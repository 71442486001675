import PropTypes from 'prop-types';
import UserStore from 'src/stores/alt/stores/UserStore';
import ElementStore from 'src/stores/alt/stores/ElementStore';

const ElementBuilder = (dic, field, currentElement) => {
  if (field.identifier === 'element.name') {
    dic[field.identifier] = currentElement.name;
    return dic;
  }
  const prop = currentElement.properties || {};
  const allFields = (prop.layers && prop.layers[field.layer_id]?.fields) || [];
  const fields = allFields.filter((f) => f.field === field.field_id) || [];
  // eslint-disable-next-line no-param-reassign
  if (fields.length > 0) dic[field.identifier] = fields[0].value;
  return dic;
};

const SegmentBuilder = (dic, field, segmentKlasses, currentElement) => {
  const klasses = segmentKlasses.filter((s) => s.identifier === field.source_id);
  if (klasses.length === 0) {
    return {};
  }
  const seg = currentElement.segments.filter((s) => klasses?.length > 0 && s.segment_klass_id === klasses[0].id);
  const prop = seg.length > 0 ? seg[0].properties : {};
  const allFields = (prop.layers && prop.layers[field.layer_id]?.fields) || [];
  const fields = allFields.filter((f) => f.field === field.field_id) || [];
  // eslint-disable-next-line no-param-reassign
  if (fields.length > 0) dic[field.identifier] = fields[0].value;
  return dic;
};

const fetchDatasets = (container, klass) => {
  let ds = null;
  if (container.container_type === 'dataset') {
    ds = container.dataset;
    if (ds && ds.klass_ols === klass.ols_term_id) {
      // console.log('fetchDatasets-ds:', ds);
      return ds;
    }
  } else {
    container.children.some((child) => {
      ds = fetchDatasets(child, klass);
      return ds != null;
    });
  }
  return ds;
};

const firstDataset = (currentElement, klass) => fetchDatasets(currentElement.container, klass);

const DatasetBuilder = (dic, field, dsKlasses, currentElement) => {
  const klasses = dsKlasses.filter((s) => s.identifier === field.source_id);
  if (klasses?.length === 0) return {};

  const ds = firstDataset(currentElement, klasses[0]);
  if (!ds) return dic;

  const prop = ds?.properties || {};

  const fields = (prop.layers && prop.layers[field.layer_id]?.fields) || [];
  const dsFields = fields.filter((f) => f.field === field.field_id) || [];
  if (dsFields.length > 0) {
    // eslint-disable-next-line no-param-reassign
    dic[field.identifier] = dsFields[0].value;
  }
  return dic;
};

const Builder = (layers, currentElement, segmentKlasses, dsKlasses) => {
  let dic = {};
  //console.log('Builder-layers:', layers);
  Object.values(layers).forEach((layer) => {
    layer.fields.forEach((field) => {
      if (field.is_voc === true && field.opid === 8) {
        if (dic[field.identifier]) return dic;

        switch (field.source) {
          case 'Element':
            dic = ElementBuilder(dic, field, currentElement);
            break;
          case 'Segment':
            dic = SegmentBuilder(dic, field, segmentKlasses, currentElement);
            break;
          case 'Dataset':
            dic = DatasetBuilder(dic, field, dsKlasses, currentElement);
            break;
          default:
            //
            break;
        }
      }
      return dic;
    });
  });
  // console.log('dic....', dic);
  return dic;
};

const DictionaryBuilder = (element) => {
  return {};

  const rootElement = (ElementStore.getState() && ElementStore.getState().currentElement) || {};
  // const genericEls = (UserStore.getState() && UserStore.getState().genericEls) || [];
  const segmentKlasses = (UserStore.getState() && UserStore.getState().segmentKlasses) || [];
  const dsKlasses = (UserStore.getState() && UserStore.getState().dsKlasses) || [];
  const layers = element.properties.layers || {};
  return Builder(layers, rootElement, segmentKlasses, dsKlasses);
};

DictionaryBuilder.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  element: PropTypes.object.isRequired,
};

export default DictionaryBuilder;
